import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import Sacramento from '../../assets/images/directory/sacramento.png'

// core components

function AboutPageHeader() {
  const styles = {
    pageHeader: {
      backgroundImage: `url(${Sacramento})`
    },
    setWidth: {
      width: '75%'
    }
  }

  let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={styles.pageHeader}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 id="land-title" className="title">Sacramento is Home</h1>
              <h3 className="description">
                On The Rocks Records has a mission to provide a home for Sacramento music artists.
                Learn about us below.
              </h3>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ?ref=creativetim"
                target="_blank"
              >
                <i className="fa fa-play" />
                Watch video
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutPageHeader;