import React from 'react';
// import { Link } from 'react-router-dom';
// import Merch from './../../assets/images/logos/OTRR_Main_Logo.png';
import Record from './../../assets/images/record-centered.png';
import {Button} from 'reactstrap';
// import RecordPlayer from '../../assets/images/Record.png'
import './styles.scss';
import { useHistory } from "react-router-dom";
// import {Button, UncontrolledCarousel} from 'reactstrap';

// import LandingDropDown from '../../paper-kit/components/Custom/LandingDropDown';


const Directory = props => {
  let history = useHistory();
  return (
    <div className="directory">
      <div className="sb-container">
        <div className="sliding-background" />
      </div>
        <div
          className="item"
          style={{
            backgroundImage: `url(${Record})`
          }}
        >
          <Button
            className="landing-button"
            color="default"
            type="button"
            onClick={(e) => {e.preventDefault(); history.push('/search')}}
          >
            Shop Records and Merch
          </Button>
        </div>

        {/*<div*/}
        {/*  className="item"*/}
        {/*  style={{*/}
        {/*    backgroundImage: `url(${Records})`*/}
        {/*  }}*/}
        {/*>*/}

        {/*  /!*<Link to="/search/womens">*!/*/}
        {/*  /!*  Records*!/*/}
        {/*  /!*</Link>*!/*/}
        {/*</div>*/}
    </div>
  );
};

export default Directory;
