
export const firebaseConfig = {
    apiKey: "AIzaSyC0BmvQhb2pQ-CJj5e0By001XoLcJlNdRQ",
    authDomain: "on-the-rocks.firebaseapp.com",
    projectId: "on-the-rocks",
    storageBucket: "futurefunkroman-debf5.appspot.com",
    messagingSenderId: "15593560792",
    appId: "1:15593560792:web:62d03a3e8d9a39b12355ad",
    measurementId: "G-D42HZL4MTK"
  };

