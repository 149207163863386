import React from 'react';
import Directory from './../../components/Directory';
import './styles.scss';
import AboutPageHeader from '../../components/AboutPageHeader';
import Footer from '../../components/Footer';

const Homepage = props => {
  return (
    <section className="homepage">
      <Directory />
      {/*<AboutPageHeader />*/}
      <Footer />
    </section>
  );
};

export default Homepage;